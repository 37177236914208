import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from './auth.service';
import { Observable, catchError, map, of } from 'rxjs';

export const canActivateTeam: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean> => {
  const authService = inject(AuthService);
  const router = inject(Router);

  const canActivateTeamResult = authService.hasValidToken();
  if (!canActivateTeamResult) {
    router.navigate(['/sign-in']);
    return of(false);
  }
  if (route.data['requiresAdmin']) {
    return authService.user$.pipe(
      map((user) => {
        if (user && user.can_update) {
          return true;
        } else {
       //   router.navigate(['/layout/home']);
          return true;
        }
      }),
      catchError((error) => {
        console.error('Error fetching user details:', error);
        router.navigate(['/layout/home']);
        return of(false);
      })
    );
  }

  return of(true);
};
export function authAppInitializerFactory(
  authService: AuthService
): () => Promise<boolean> {
  return () => authService.runInitialLoginSequence();
}
